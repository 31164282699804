import App from "../core/app"

import Toolbar from "./components/app-toolbar"
import Content from "./components/app-content"
import ConfirmDialog from "./dialogs/confirm-dialog"
import Dialog from "./dialogs/dialog"

const app = new App()

app.components(
  Toolbar,
  Content,
  ConfirmDialog,
  Dialog
)

export default app
