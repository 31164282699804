<template>
  <v-main class="app-content">
    <v-container fluid>

    </v-container>

  </v-main>
</template>

<script>
import Panels from './panels';

export default {
  name: 'app-content',
  components: {
    Panels
  },
};
</script>

<style lang="less">
.app-content {
  height: 100%;
  .v-main__wrap > .container {
    height: 100%;
    padding: 0;
  }
}
</style>
