<template>
  <div class="panel-wrapper">
    <v-navigation-drawer
      v-for="(p, index) in panels"
      :key="index"
      v-model="p.opened"
      :width="
        ['left', 'right'].includes(p.position) && !fullscreen ? `${p.size}%` : '100%'
      "
      :height="['top', 'bottom'].includes(p.position) ? `${p.size}%` : '100%'"
      :class="{ 'v-navigation-drawer--left': p.position === 'left' }"
      :right="p.position === 'right'"
      :left="p.position === 'left'"
      :bottom="p.position === 'bottom'"
      :style="{
        zIndex: startZIndex + index,
        pointerEvents: index === panels.length - 1 ? 'auto' : 'none',
      }"
      fixed
      stateless
      temporary
    >
      <div class="panel-inner">
        <div class="panel-top">
          <span class="panel-title text-h5">{{ p.title }}</span>
          <v-btn
            :loading="p.loading"
            class="mr-2"
            v-for="({ label, action, uiOptions = {} }, i) in p.actions"
            :color="uiOptions.color"
            :outlined="uiOptions.outlined"
            :disabled="uiOptions.disabled"
            :key="i"
            @click="executeAction(p, action)"
            >{{ label }}</v-btn
          >
          <v-btn
            class="mr-2"
            @click="$panel.close()"
            elevation="2"
            min-width="36"
            max-width="36"
            width="36"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-divider />
        <component :is="p.component" v-bind="p.props"></component>
      </div>
    </v-navigation-drawer>
    <app-confirm-dialog ref="closePanelDialog"></app-confirm-dialog>
  </div>
</template>

<script>
import { isNil } from "lodash";
import { Z_INDEXES } from "../../constants";

export default {
  name: "panels",
  data() {
    return {
      panels: [],
      fullscreen: false,
      minScreenWidth: 768,
      startZIndex: Z_INDEXES.panelStart,
    };
  },
  created() {
    window.addEventListener("resize", this.updateWidth);
  },
  mounted() {
    this.$eventBuss.on(this.$panel.events.OPEN_COMPONENT, this.onOpen);
    this.$eventBuss.on(this.$panel.events.CLOSE, this.onClose);
    this.$eventBuss.on(this.$panel.events.CLOSE_ALL, this.onCloseAll);
    this.$eventBuss.on(this.$panel.events.SET_TITLE, this.onSetTitle);
    this.$eventBuss.on(this.$panel.events.SET_ACTIONS, this.onSetActions);
    this.$eventBuss.on(this.$panel.events.DATA_CHANGED, this.onDataChanged);

    document.addEventListener("click", this.onBackdropClick);
    this.updateWidth();
  },
  destroyed() {
    this.$eventBuss.off(this.$panel.events.OPEN_COMPONENT, this.onOpen);
    this.$eventBuss.off(this.$panel.events.CLOSE, this.onClose);
    this.$eventBuss.off(this.$panel.events.CLOSE_ALL, this.onCloseAll);
    this.$eventBuss.off(this.$panel.events.SET_TITLE, this.onSetTitle);
    this.$eventBuss.off(this.$panel.events.SET_ACTIONS, this.onSetActions);
    this.$eventBuss.off(this.$panel.events.DATA_CHANGED, this.onDataChanged);

    document.removeEventListener("click", this.onBackdropClick);
  },
  methods: {
    updateWidth() {
      if (window.innerWidth < this.minScreenWidth) {
        this.fullscreen = true;
      } else this.fullscreen = false;
    },
    onBackdropClick(e) {
      const isOverlayClick = Boolean(e.target.closest(".v-overlay"));
      const isPanelOpened = Boolean(this.panels.length);

      if (isOverlayClick && isPanelOpened) {
        this.$panel.close();
      }
    },
    async onOpen({ component, props, settings }) {

      const panel = this.panels.find((p) => p.component.name === component.name);

      if (panel) return

      let panelSettings = {
        position: settings.position,
        oldSize: settings.size,
        size: settings.size,
        title: settings.title,
        needCloseConfirm: settings.needCloseConfirm,
        opened: false,
      };

      const newPanel = { ...panelSettings, component, props };

      this.panels.push(newPanel);

      setTimeout(() => {
        const topPanel = this.panels[this.panels.length - 1];
        topPanel.opened = true; 

        if (this.panels.length > 1) {
          this.panels.forEach(function(item, index, array) {
            if (index !== array.length - 1) {
              item.size = topPanel.size + (5 * (array.length - (index + 1)));
            } 
          });
        }
      }, 300);  

      this.$set(this.panels[this.panels.length - 1], "dataChanged", false);
    },
    async onClose({ force }) {
      const topPanel = this.panels[this.panels.length - 1];

      if (isNil(topPanel)) return;

      const { needCloseConfirm, dataChanged } = topPanel;

      if (
        !dataChanged ||
        !needCloseConfirm ||
        force ||
        (needCloseConfirm &&
          (await this.$refs.closePanelDialog.open(
            this.$t("panel.needCloseConfirm")
          )))
      ) {

        topPanel.opened = false;
        this.panels.splice(-1, 1);
      }

      if (this.panels.length) {
        const newTopPanel = this.panels[this.panels.length - 1];
        newTopPanel.size = newTopPanel.oldSize;

        if (this.panels.length > 1) {
          this.panels.forEach(function(item, index, array) {
            if (index !== array.length - 1) {
              item.size = newTopPanel.size + (5 * (array.length - (index + 1)));
            } 
          });
        }
      }
    },
    async onCloseAll({ force }) {
      if (force) {
        this.panels = [];
      }
    },
    onSetTitle({ id, title }) {
      const panel = this.panels.find((p) => p.component.name === id);

      this.$set(panel, "title", title);
    },
    onSetActions({ id, actions }) {
      const panel = this.panels.find((p) => p.component.name === id);

      if (!isNil(panel)) this.$set(panel, "actions", actions || []);
    },
    async executeAction(panel, action) {
      this.$set(panel, "loading", true);

      await action();

      this.$set(panel, "loading", false);
    },
    onDataChanged({ id, value }) {
      const panel = this.panels.find((p) => p.component.name === id);

      this.$set(panel, "dataChanged", value);
    },
  },
};
</script>

<style lang="less">
.panel-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.panel-top {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 1em 0 1em 1em;
}

.panel-title {
  flex: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.panel-close {
  //margin-left: auto;
  //padding: 0;
  //background: none;
  border: solid 1px #ccc;
  border-top-right-radius: 0;
  //box-shadow: none;
  border-right: none;
}

.panel-content {
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: hidden;
}
.panel-content-body {
  flex: auto;
  overflow-y: auto;
  padding: 0 1em;
}

.panel-content-header {
  padding: 1em;
}

.panel-content-footer {
  margin-top: auto;
  padding: 1em;
}

.panel-wrapper {
  .v-navigation-drawer:not(.v-navigation-drawer--right):not(.v-navigation-drawer--left):not(.v-navigation-drawer--bottom) {
    &.v-navigation-drawer--open {
      transform: translateY(0) !important;
    }

    &.v-navigation-drawer--close {
      transform: translateY(-100%) !important;
    }
  }

  .v-navigation-drawer.v-navigation-drawer--bottom {
    top: auto !important;
    bottom: 0 !important;

    &.v-navigation-drawer--open {
      transform: translateY(0) !important;
    }

    &.v-navigation-drawer--close {
      transform: translateY(100%) !important;
    }
  }

  .v-navigation-drawer__content {
    overflow-y: hidden !important;
  }
}
</style>
