<template>
  <div class="app-toolbar">
    <eva-image :src="appLogoUrl"/>
    <div class="app-name">{{ appName }}</div>
    <eva-spacer/>
    <div>{{ userName }}</div>
    <img :src="userLogoUrl" class="app-avatar">
  </div>
</template>

<script>
export default {
  name: 'app-toolbar',

  computed: {
    appName() {
      const platformName = this.$context.platform?.platformName;
      return platformName
          ? platformName[this.$locale.current]
          : this.$t("app.name");
    },
    appLogoUrl() {
      return this.$context.platform && this.$context.platform.logoUrl;
    },
    userName() {
      let name = this.$context.user.username;
      if (this.$context.user.ref_person) {
        return `${name} (${this.$context.user.ref_person.name || '-'})`
      }
      return name;
    },
    userLogoUrl() {
      const user = this.$context.user;
      return user.logo
          ? user.logo.contentUrl
          : '/assets/images/no_photo.png';
    }
  }
};
</script>

<style lang="less">
.app-toolbar {
  height: @eva-header;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 @eva-padding 0 0;
  gap: @eva-padding;
  img {
    height: 100%;
  }
  .app-avatar {
    border-radius: 50%;
    padding: (@eva-padding / 2) 0;
  }
  .app-name {
    font-weight: bold;
    color: #00468c;
  }
}
</style>
